import { makeStyles } from '@material-ui/core/styles';

import theme from '@/src/uprightTheme';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
  },
  signInBtn: {
    marginTop: '1.5rem',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    '&:hover': {
      background: '#E64B13',
    },
  },
  googleSignInBtn: {
    backgroundColor: theme.palette.background.default,
    color: '#2F2F2F',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
  },
  uprightText: {
    marginTop: '1.25rem',
    color: '#FFF',
    textAlign: 'center',
  },
  biggerDots: {
    '& input[type="password"]': {
      fontFamily: 'Verdana',
      letterSpacing: '0.105rem',
    },
  },
  forgotPasswordText: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export { useStyles };
