import {
  Box,
  Button,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import clsx from 'clsx';

import { ACTION_TYPES } from '@/src/constants';
import { useStyles } from '@/styles/signinStyles';

import GoogleSvgIcon from '../GoogleSvgIcon';

const SignInForm = ({ continueWithGoogle, dispatch, signIn, state }) => {
  const classes = useStyles();

  const handleFormChange = (evt) => {
    const { name, value } = evt.target;
    dispatch({
      type: ACTION_TYPES.SET,
      payload: { [name]: value },
    });
  };

  return (
    <Container maxWidth='xs'>
      <Typography variant='body1'>Welcome Back</Typography>
      <Typography component='h1' variant='h4'>
        Sign in to your account
      </Typography>
      <form onSubmit={(evt) => evt.preventDefault()}>
        <TextField
          fullWidth
          aria-label='email'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <PersonOutlineIcon />
              </InputAdornment>
            ),
          }}
          label='Email'
          margin='normal'
          name='email'
          value={state.email}
          variant='outlined'
          onChange={handleFormChange}
        />
        <TextField
          fullWidth
          aria-label='password'
          className={clsx(state.password.length && classes.biggerDots)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <VisibilityOffOutlinedIcon />
              </InputAdornment>
            ),
          }}
          label='Password'
          margin='normal'
          name='password'
          type='password'
          value={state.password}
          variant='outlined'
          onChange={handleFormChange}
        />
        <Box display='flex' justifyContent='right'>
          <Typography
            className={classes.forgotPasswordText}
            color='primary'
            variant='subtitle1'
            onClick={() => dispatch({ type: ACTION_TYPES.SHOW_MODAL })}
          >
            Forgot Password?
          </Typography>
        </Box>
        <Button
          fullWidth
          className={classes.signInBtn}
          type='submit'
          variant='outlined'
          onClick={signIn}
        >
          Sign In
        </Button>
        <Button
          fullWidth
          className={classes.googleSignInBtn}
          startIcon={<GoogleSvgIcon />}
          variant='outlined'
          onClick={continueWithGoogle}
        >
          Continue with Google
        </Button>
      </form>
      <Box display='flex' justifyContent='center' mt={4}>
        <Typography variant='subtitle1'>
          Don&apos;t have an account? &nbsp;
        </Typography>
        <Link href='/signup' variant='subtitle1'>
          Sign Up
        </Link>
      </Box>
    </Container>
  );
};

export default SignInForm;
