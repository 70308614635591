import { makeStyles } from '@material-ui/core/styles';

import theme from '@/src/uprightTheme';

const useStyles = makeStyles(() => ({
  resetBtn: {
    marginTop: '1.5rem',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    '&:hover': {
      background: '#E64B13',
    },
  },
  cancelBtn: {
    marginTop: '1.5rem',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    '&:hover': {
      background: '#FFF',
    },
  },
}));

export { useStyles };
