/* eslint-disable @next/next/no-img-element */
import { useReducer } from 'react';

import { Box, Container, Typography } from '@material-ui/core';
import { useRouter } from 'next/router';

import AuthFormContainer from '@/components/AuthFormContainer';
import ForgotPasswordForm from '@/components/ForgotPasswordForm';
import SignInForm from '@/components/SignInForm';
import { ACTION_TYPES } from '@/src/constants';
import { ErrorCodeToPayload } from '@/src/types';
import { useStyles } from '@/styles/signinStyles';

import {
  sendResetEmail,
  signInUser,
  signInWithGoogle,
} from '../utilities/auth';

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET:
      return { ...state, ...payload };
    case ACTION_TYPES.SET_ERROR:
      return { ...state, isError: true, message: payload.message };
    case ACTION_TYPES.SHOW_MODAL:
      return { ...state, forgotPasswordOpen: true };
    case ACTION_TYPES.CLOSE_MODAL:
      return { ...state, forgotPasswordOpen: false };
    case ACTION_TYPES.RESET:
      return init(initialState);
    default:
      console.error(`Action Type ${type} not allowed`);
      return { ...state };
  }
};

const init = (initialState) => {
  return { ...initialState };
};

const initialState = {
  email: '',
  forgotPasswordOpen: false,
  isError: false,
  message: '',
  password: '',
};

const SignIn = () => {
  const classes = useStyles();
  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState, init);

  const signIn = async () => {
    const { email, password } = state;
    try {
      const user = await signInUser(email, password);
      dispatch({ type: ACTION_TYPES.RESET });
      dispatch({
        type: ACTION_TYPES.SET,
        payload: {
          isError: false,
          message: `${user.displayName} has signed in`,
        },
      });
      router.push('/dashboard');
    } catch (error) {
      const errorCodeToPayload: ErrorCodeToPayload = {
        'auth/user-not-found': {
          isError: true,
          message: `Cannot find user with email ${state.email}`,
        },
        'auth/wrong-password': {
          isError: true,
          message: 'You have entered the wrong password',
          password: '',
        },
        default: { isError: true, message: error.message },
      };

      return dispatch({
        type: ACTION_TYPES.SET,
        payload: errorCodeToPayload[error.code ? error.code : 'default'],
      });
    }
  };

  const continueWithGoogle = async () => {
    try {
      await signInWithGoogle();
      router.push('/dashboard');
    } catch (error) {
      console.error({ error });
      dispatch({
        type: ACTION_TYPES.SET,
        payload: { isError: true, message: error.message },
      });
    }
  };

  const sendForgotPassEmail = async () => {
    const setMessage = (message: string): void => {
      dispatch({ type: ACTION_TYPES.RESET });
      const type = message.toLowerCase().includes('failed')
        ? ACTION_TYPES.SET_ERROR
        : ACTION_TYPES.SET;

      dispatch({
        type,
        payload: { message },
      });
    };
    sendResetEmail(state.email, setMessage);
  };

  const { isError, message } = state;

  return (
    <Container
      disableGutters
      className={classes.root}
      component='main'
      maxWidth={false}
    >
      <Box
        alignItems='center'
        bgcolor='primary.main'
        display='flex'
        justifyContent='center'
        width='50vw'
      >
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          maxWidth='260px'
        >
          <img alt='white upright logo' src={'/SVG/upright_white.svg'} />
          <Typography className={`${classes.uprightText}`} variant='body1'>
            Course material to get you started down the right path
          </Typography>
        </Box>
      </Box>
      <AuthFormContainer error={isError} message={message}>
        {state.forgotPasswordOpen ? (
          <ForgotPasswordForm
            dispatch={dispatch}
            sendForgotPassEmail={sendForgotPassEmail}
            state={state}
          />
        ) : (
          <SignInForm
            continueWithGoogle={continueWithGoogle}
            dispatch={dispatch}
            signIn={signIn}
            state={state}
          />
        )}
      </AuthFormContainer>
    </Container>
  );
};

export default SignIn;
