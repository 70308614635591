import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { ACTION_TYPES } from '@/src/constants';

import { useStyles } from './styles';

const ForgotPasswordForm = ({ dispatch, state, sendForgotPassEmail }) => {
  const classes = useStyles();

  return (
    <Container maxWidth='xs'>
      <Typography variant='body1'>Forgot Password?</Typography>
      <Typography component='h1' variant='h4'>
        Enter your email:
      </Typography>
      <form onSubmit={(evt) => evt.preventDefault()}>
        <TextField
          fullWidth
          aria-label='email'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <PersonOutlineIcon />
              </InputAdornment>
            ),
          }}
          label='Email'
          margin='normal'
          value={state.email}
          variant='outlined'
          onChange={(evt) =>
            dispatch({
              type: ACTION_TYPES.SET,
              payload: { email: evt.target.value },
            })
          }
        />
        <Button
          fullWidth
          className={classes.resetBtn}
          type='submit'
          variant='outlined'
          onClick={sendForgotPassEmail}
        >
          Send Password Reset Email
        </Button>
        <Button
          fullWidth
          className={classes.cancelBtn}
          type='submit'
          variant='outlined'
          onClick={() => dispatch({ type: ACTION_TYPES.CLOSE_MODAL })}
        >
          Cancel
        </Button>
      </form>
    </Container>
  );
};

export default ForgotPasswordForm;
